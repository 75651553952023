<template>
	<div class="legal-page">
		<div class="inner-page" @scroll="pageScroll($event)">
			<div class="content-container">
				<div class="indent fixed-width mb-5">
					
					<h1>
						<span id="investments-title" class="txtan">{{data.attributes.titleLineFirst}} </span><span id="investments-title-2" class="txtan light">{{data.attributes.titleLineSecond}}</span>
					</h1>
					<span class="timestamp">Last updated: <strong>{{data.attributes.officialPublishDate}}</strong></span>
					<div class="hr"></div>

					<div v-html="data.attributes.content">
						
					</div>

					
				</div>
			</div>
		</div>
		<div class="page-background" id="background-video">
			<video :class="{ show: videoReady }" autoplay muted loop @loadeddata="videoLoaded()">
				<source src="../assets/videos/bg3.webm" type="video/webm">
			</video>
		</div>
	</div>
</template>

<script>

import axios from 'axios';

export default {
	name: "Legal",
	data() {
		return {
			loading: true,
			videoReady: false,
			data: {
				attributes: {
					content: 'Loading page...',
					titleLineFirst: '',
					titleLineSecond: '',
					officialPublishDate: ''
				}
			}
		};
	},
	mounted(){
		this.$nextTick(function () {			
			this.loadData(this.$route.params.itemname);
		});
	},
	beforeRouteUpdate (to){
		this.loadData(to.params.itemname);
	},
	methods: {
		videoLoaded() {
			this.videoReady = true;
		},
		pageScroll(event){
			
			const yScrollPos = event.target.scrollTop
			
			const yScrollStart = 50;
			const yScrollDistance = 500;

			const yScroll = (yScrollPos < yScrollStart) ? 0 : (yScrollPos - yScrollStart);
			const yScrollPct = (yScroll / yScrollDistance > 1) ? 1 : (yScroll / yScrollDistance).toFixed(2);
			
			if (this.pageScrollPos != 1 - yScrollPct){
				const opacity = (1 - yScrollPct).toFixed(2);
				this.pageScrollPos = opacity;
				const videoElem = document.getElementById('background-video');
				videoElem.style.opacity = opacity;
			}

		},
		loadData(slug){
			// Get GPS Position
			this.$store.commit('setLoading', true);
			axios.get(this.$store.state.app.apiUrl + '/legal-pages?filters[slug][$eq]=' + slug).then(response => {
				this.$store.commit('setLoading', false);
				this.data = response.data.data[0];	
			});
		}
	}
};
</script>

<style lang="scss">

	@import "../scss/abstracts/_variables.scss";

	.legal-page {
		
		.inner-page {

			.hr {
				background-color: $gray-700;
				height:2px; 
				width: 100%;
				margin: ($margin-m*1.75) 0;
			}

			.content-container .fixed-width {
				max-width: 1000px !important;

				h3 {
					margin-top:32px !important;
					margin-bottom: 0px !important;
				}

				p {
					margin-top: 6px !important;
					margin-bottom:16px !important;
				}

			}

			.timestamp {
				
				color: $gray-300;
				
				strong {
					display: inline-block; 
					color: $text-color;
					padding-left: 10px;
				}
			}

		}

	}

</style>